$new_palette: (
    a: #AD8500,
    b: #FFD13B,
    c: #FAC000,
    d: #0017AD,
    e: #8292FA,

    f: #292929, // darkgrey
    g: #f3f3f3, // lightgrey

    h: #c3cbff, // lightblue
    i: #16226B, //darkblue
);

// Body
$body-bg: #f3f3f3;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue: #16226B;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #96221e;
$orange: #f6993f;
$yellow: #FAC000;
$green: #157e41;
$teal: #4dc0b5;
$cyan: #c3cbff;

$primary:       $blue !default;
$secondary:     $gray-700 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-900 !default;

// Spacing
$headings-margin-bottom: 1rem;

// Inputs
$input-bg: white;

// Navbar
$navbar-dark-color: rgba($white, .75);
$navbar-dark-hover-color: rgba($white, .95);

// Cards
$card-cap-bg: $gray-700;
$card-cap-color: white;
