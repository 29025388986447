// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito&family=Fugaz+One&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import "~@fortawesome/fontawesome-free/css/fontawesome";
@import "~@fortawesome/fontawesome-free/css/solid";
@import "~@fortawesome/fontawesome-free/css/brands";

[x-cloak] { display: none !important; }

.font-allroads {
    font-family: Fugaz One, cursive;
}

ul, ol {
    margin-bottom: 0;
}

code {
    color: #00661e;
}

pre {
    font-size: inherit;
}

.form-group {
    @extend .mb-2;
}

.navbar-expand-md .navbar-collapse {
    justify-content: end;
}

.btn-plain {
    background: none;
    border: none;
}

.glow-arlightblue {
    box-shadow: 0 0 5px 5px #8292FA;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0017AD;
    border-color: #0017AD;
}

a:hover {
    color: #0017AD;
}

.btn-link:hover {
    color: #0017AD;
}
